<script setup>
import {useAuthStore} from "../../stores/auth.store";
import {authHeader} from "../../helpers/authheader";
import {useWizardStore} from "../../stores/wizard.store";
import {router} from '../../helpers/router';
import {utilsMixin} from '../../helpers/utils';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import LoadingAnimation from "./LoadingAnimation.vue";
import uikit from "uikit";
</script>
<script>
export default {
  mixins: [utilsMixin],
  data() {
    return {
      activePattern: {},
      pageId: 'library',
      baseUrl: process.env.APIURL,
      wizard: useWizardStore(),
      loadedFromWizard: false,
      imageList: [],
      patternList: [],
      patternTagsReversed: [],
      productList: {},
      // showBackground: true,
      libraryMessage: "",
      loadingError: "",
      checkoutComplete: false,
      showLibraryMessage: false,
      isLibraryError: false,
      loaded: false,
      product_type: "Rolled",
      configData: {},
      patternData: {},
      cartItem: {},
      franchise: process.env.Franchise,
      addToCartSuccess: false,
      scrollTags: [],
      // loadedScrollTags: [],
      substrateFilters: [],
      patternFilters: [],
      showFilterControls: false,
      publicLibraryPattern: '',
    }
  },
  async mounted() {
    //
    // first run to get wood
    //
    
    if (this.currentPath !== 'PublicLibrary') {
      this.setConfigData();
    }

    await this.getPatternListFirstLoad('Wood');

    window.addEventListener('scroll', this.handleScroll);

    //
    // Second run to get the rest of the data
    //
    // this.prestageFromWizard();

    this.getPatternList();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    count() {
      let count = 0;
      if (this.patternData.DesignRows) {
        // console.log("Count is: ", this.patternData.DesignRows.length);
        this.patternData.DesignRows.forEach(element => {
          element.forEach(item => {
            count++;
          });
        });
        return count;
      }
      // console.log("Setting count to 0");
      return count;
    },
    currentPath() {
      // const path = computed(() =>router.path)
      let path = router.currentRoute.value.path;
      path = path.substring(1, path.length);
      // console.log("currentPath", path);
      return path;
    },
    productOptionChoices() {
      // console.log("productOptionChoices", this.patternData);
      // console.log("configData", this.configData);
      let choiceData = {};
      choiceData = _.clone(this.configData.dtOptions);
      // log("choiceData", choiceData);
      // Need to remove product_type
      _.pullAllBy(choiceData, [{
        'OptionKey': 'product_type'
      }], 'OptionKey');
      // log("productOptionChoices", choiceData);
      return choiceData;
    },
    patterns() {
      // console.log("Patterns Start", this.patternList);
      // console.log("Patterns Filters", this.patternFilters);
      let returnValue = [];
      if (this.patternList) {
        this.patternList.forEach(element => {
          let elArray = [];
          element.forEach(item => {
            if (this.patternFilters.length > 0) {
              let matchAllFilters = true;
              this.patternFilters.forEach(filter => {
                // console.log("Patterns", filter);
                // check if item.tagList contains filter
                filter = this.regexSpaces(filter);
                if (!item.tagList.includes(filter)) {
                  matchAllFilters = false;
                }
              });
              if (matchAllFilters) {
                elArray.push(item);
              }
            } else {
              elArray.push(item);
            }
            // returnValue.push(item);
          });
          returnValue.push(elArray);
        });
      }
      return returnValue;
    },
    // showPrice() {
    //   return this.showPortalPrice();
    // }
  },
  methods: {
    prestageFromWizard() {
      if (this.wizard.wizardActive) {
        // log("wizard substrateName: ", this.wizard.substrateName);
        // log("wizard substrateValue:", this.wizard.substrateValue);
        // log("wizard product type: ", this.wizard.productType);
        this.activePattern.ItemNo = this.wizard.substrateValue;
        this.activePattern.MaterialText = this.wizard.substrateName;
        this.activePattern.tile = this.wizard.substrateTile;
        this.loadedFromWizard = true;
        // this.wizard.wizardActive = false;
      }
    },
    async getScrollTags() {
      // log("fetchPatternConfig");
      this.loadingError = "";
      this.fileAction = "browse";
      const url = this.baseUrl + "/PatternLibrary/GetScrollTags";
      let userID = 0;
      const {user} = useAuthStore();

      if (user) {
        userID = user.Id;
      }
      const data = {
        "userId": userID,
        "store_id": this.franchise,
        "PageId": this.pageId
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const tags = await response.json();
      // log("Scroll tags: ", tags);
      
      return tags;
    },
    async fetchCategoryData(category){
      // log("fetchPatternConfig");
      // console.log("fetchCategoryData", category);
      this.loadingError = "";
      this.fileAction = "browse";
      const url = this.baseUrl + "/PatternLibrary/GetCategoryData";
      let userID = 0;
      const {user} = useAuthStore();

      if (user) {
        userID = user.Id;
      }
      const data = {
        "userId": userID,
        "store_id": this.franchise,
        "PageId": this.pageId,
        "fetchTag": category,
        "count" : this.count
      };
      // console.log("data on fetchCatData: ", data);
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const fetchData = await response.json();
      // log("Data for category: ", category, ' - Data: ', fetchData);
      // if (tags.includes("Wood")) {
      //   this.fetchCategoryData('Wood');
      // }
      // this.loadedScrollTags.push(category);
      this.loaded = true;

      // this.populateImageList();
      // this.populatePatternList();
      return fetchData;
    },
    validOrder() {
      let isValid = false;
      if (this.activePattern.Quantity < 1) {
        this.isLibraryError = true;
        this.libraryMessage = "Quantity must be greater than 0";
        return false;
      }

      if (this.activePattern.ItemNo && this.activePattern.Type && this.activePattern.rollSize) {
        this.isLibraryError = false;
        // log("Valid order", this.activePattern);
        return true;
      }

      this.isLibraryError = true;
      this.libraryMessage = "Invalid Order. Please complete all required fields.";
      // log("Not a valid order");
      return false;
    },
    addPatternToCart() {
      // log("addPatternToCart");
      const url = this.baseUrl + "/Store/AddToCart";
      const {user} = useAuthStore();
      let call = [];
      // add user id
      let patternClone = {...this.activePattern};
      // console.log("patternClone", patternClone);
      patternClone.userId = user.Id;
      patternClone.base64String = "";
      call.push(patternClone);
      // log("the cart call", call);
      fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then(jsondata => {
        if (jsondata.Status === "ok") {
          this.libraryMessage = jsondata.Message;
          this.isLibraryError = false;
          this.addToCartSuccess = true;
        }
        if (jsondata.Status === "exception") {
          this.libraryMessage = jsondata.Message;
          this.isLibraryError = true;
        }
      });
    },
    async fetchPatternConfig() {
      // log("fetchPatternConfig");
      this.loadingError = "";
      this.fileAction = "browse";
      const url = this.baseUrl + "/PatternLibrary/GetPatternLibrary";
      let userID = 0;
      const {user} = useAuthStore();

      if (user) {
        userID = user.Id;
      }
      const data = {
        "userId": userID,
        "store_id": this.franchise,
        "PageId": this.pageId
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const libResult = await response.json();
      // log("fetchPatternConfig json", libResult);
      return libResult;
    },
    getChoiceText(name, available) {
      // log("getChoiceText");
      let returnValue = name;
      if (available == false) {
        returnValue = returnValue + " (Temporarily Unavailable)";
      }
      return returnValue;
    },
    getMaterialText(ItemNo) {
      // log("getMaterialText");
      let materialText = "";
      if (ItemNo) {
        let what = _.filter(this.configData.dtOptionChoices, ['ChoiceValue', ItemNo]);
        if (what) {
          materialText = what[0].ChoiceName;
        }
      }
      return materialText;
    },
    initChoiceOptions(myKey) {
      // Populates the dropdown with substrate options
      // log("initChoiceOptions", myKey);
      let _this = this;
      let what = _.filter(this.configData.dtOptionChoices, ['OptionKey', myKey]);
      let choices = what;
      // log("initChoiceOption", what);
      if (myKey === "ItemNo") {
        choices = [];
        _.forEach(what, function (it, i) {
          // console.log("PatternTags", _this.patternData.ttPatternTags);
          const allowed = _.find(_this.patternData.ttPatternTags, ["tagValue", it.ChoiceValue]);
          // console.log("allowed", allowed);
          if (allowed) {
            choices.push(it);
          }
        });
      }
      // console.log("choices", choices);
      const sorted = _.sortBy(choices, ["ChoiceSort"]);
      // log("sorted", sorted);
      return sorted;
    },
    /*initPatternOptions(PatternName) {
      // Populates the dropdown with substrate options
      log("initPatternOptions ttPatternData", this.patternData);
      log("initPatternOptions PatternName", PatternName);
      let pattern = _.filter(this.patternData.ttPatternTags, ['designName', PatternName]);
      log("pattern", pattern);
      // let what = _.filter(pattern, ['tagType', myKey]);
      // log("initPatternOptions what", what);
      // return _.sortBy(what, ["ChoiceSort"])
    },*/
    resetPage() {
      window.location.reload();
    },
    /*getSvgDataURL(svgString) {
      const svgData = `data:image/svg+xml;base64,${btoa(svgString)}`;
      return svgData;
    },*/
    choiceChange() {
      // console.log("choiceChange current activePattern: ");
      // console.log(this.activePattern);
      if (this.currentPath !== 'PublicLibrary') {
        this.setConfigData();
      }
    },
    checkOptionAvailable(OptionKey, image) {
      //Using the dtOptionCondition determine if the option is valid for the users choices.
      // console.log("checkOptionAvailable: " + OptionKey);
      //console.log("Current configuration data: ");
      //console.log(this.configData);
      const OptionConditions = this.configData.dtOptionCondition;
      const conditions = _.filter(OptionConditions, ['OptionKey', OptionKey]);
      //console.log("checkOptionAvailable: conditions:");
      //console.log(conditions);
      if (conditions.length > 0) {
        //console.log("activePattern:");
        //console.log(this.activePattern);
        for (let k in conditions) {
          let v = conditions[k];
          //console.log("other option key: " + v.OtherOptionKey);
          let currentState = this.activePattern[v.OtherOptionKey];
          if (image) {
            currentState = image[v.OtherOptionKey];
          }
          //console.log("currentState: " + currentState);
          //console.log("Condition: " + v.Condition)
          //console.log("ConditionValue: " + v.ConditionValue);

          // ** NOTE the reverse test for the implied case. **
          switch (v.Condition) {
            case 'EQ':
              if (!(currentState == v.ConditionValue)) {
                return false;
              }
              break;
            case 'NEQ':
              if (currentState == v.ConditionValue) {
                return false;
              }
              break;
            case 'GT':
              if (!(parseFloat(currentState) > parseFloat(v.ConditionValue))) {
                return false;
              }
              break;
            default:
          }
        }
      }
      //console.log("Default true");
      return true;
    },
    setConfigData: _.debounce(async function () {
      // if (this.currentPath === 'PublicLibrary'){
      //   return;
      // }
      let jsonData = {};
      let data = await this.fetchConfigData();
      // log("setConfigData return: data", data);  <-- this sometimes throws an error that it is not JSON -->
      jsonData = JSON.parse(data);
      // log("setConfigData return: JsonData", jsonData);
      this.configData = jsonData;
      // log("SetConfigData ", this.configData);
      //this.zHardCodeTesting();

      this.manageProductRules(this.product_type);
    }, 100),
    async fetchConfigData() {
      if (this.currentPath === 'PublicLibrary') {
        return;
      }
      // console.log("fetchConfigData");
      //#DECORHACK
      /*if (this.franchise === "Decor") {
        // this.Franchise = 'Wholesale'
        this.franchise = 'NGS'
      }*/
      // #WebPrintHack
      if (this.franchise === 'WebPrint') {
        // call.Franchise = 'Wholesale'
        this.franchise = 'Wholesale'
      }
      this.activePattern.franchise = this.franchise;
      const url = this.baseUrl + "/Portal/GetProductOptions";
      const {user} = useAuthStore();
      let userID = 0;

      if (user) {
        userID = user.Id;
      }
      // log("Active Pattern: ", this.activePattern);
      let call = _.clone(this.activePattern);
      call.userId = userID;
      call.product_type = this.product_type;
      call.PageId = this.pageId;
      call.base64String = "";
      // log("fetchConfigData call", call);
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let configData = await responseData.json();
      // log("configData Response", configData);
      return configData;
    },
    manageProductRules(product) {
      // log("manageProductRules", product);
      // This is an object literal with a default return type.
      let _this = this;
      //console.log(this.activePattern);
      let defaults = {
        'Rolled': function () {
          if (!_.isEmpty(_this.configData)) {
            // console.log("config data: ", _this.configData);
            let productArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.activePattern.ItemNo]);
            if (productArray.length > 0 && !_this.loadedFromWizard) {
              // log("Setting Product");
              let product = productArray[0];
              if (product) {
                _this.activePattern.ItemDesc = product.ChoiceName;
                _this.activePattern.MaterialText = product.ChoiceName;
              }
            }
            if (productArray.length > 0 && _this.loadedFromWizard) {
              _this.activePattern.ItemDesc = _this.wizard.substrateName;
              _this.activePattern.MaterialText = _this.wizard.substrateName;
              // _this.activePattern.Type = _this.wizard.substrateLaminateType;
            }
            let typeArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.activePattern.Type]);
            if (typeArray.length > 0 && !_this.loadedFromWizard) {
              let type = typeArray[0];
              // log("type", type);
              if (type) {
                // log("chosen type is", _this.activePattern.Type);
                // _this.activePattern.Type = type.ChoiceValue;
                _this.activePattern.FinishText = type.ChoiceName;
                _this.activePattern.ItemDesc = _this.activePattern.ItemDesc + " - " + type.ChoiceName;
                _this.activePattern.MaterialText = _this.activePattern.MaterialText + " - " + type.ChoiceName;
              }
            }
            let lengthArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.activePattern.rollSize]);
            if (lengthArray.length > 0) {
              let length = lengthArray[0];
              // console.log("rollSize", length);
              if (length) {
                // console.log("Setting Roll Length with Length", _this.activePattern.rollSize);
                // _this.activePattern.Type = type.ChoiceValue;
                _this.activePattern.ItemDesc = _this.activePattern.ItemDesc + " - " + length.ChoiceName;
                _this.activePattern.MaterialText = _this.activePattern.MaterialText + " - " + length.ChoiceName;
              }
            }

            _this.updatePricing();
          }
        },
        // no product or blank string.
        '': function () {
          // log("manageProductRules no product");
          let searchParams = new URLSearchParams(window.location.search);
          let cartId = searchParams.get("editid");
          if (cartId) {
            _this.editMyCartItem(cartId);
          }
          if (!cartId) {
            // UIkit.modal("#ProductListModal").show();
          }
        }
      }
      return (defaults[product] || defaults['none'])();
    },
    async populateImageList() {
      // log("populateImageList patternData", this.patternData);
      // const tmpImages = this.patternData.Designs;
      let tmpImages = [];
      let designs = this.patternData.DesignRows;
      // console.log("designs", designs);
      designs.forEach(element => {
        element.forEach(item => {
          tmpImages.push(item);
        });
      });

      // tmpImages.forEach(element => {
      //   element.tagList = this.getCategoryTag(element);
      // });
      // tmpImages.sort()
      // tmpImages.sort(function(a, b) {
      //   var nameA = a.tagList.toUpperCase(); // ignore upper and lowercase
      //   var nameB = b.tagList.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1; // nameA comes first
      //   }
      //   if (nameA > nameB) {
      //     return 1; // nameB comes first
      //   }
      //   // names must be equal
      //   return 0;
      // });
      if (tmpImages) {
        // console.log("tmpImages", tmpImages);
        this.imageList = tmpImages;
        this.loaded = true;
      }
    },
    async populatePatternList() {
      // log("populatePatternList patternData", this.patternData);
      // filter ttPatterns by category and then alphabetize
      let _this = this;
      this.scrollTags = this.patternData.ScrollTags;
      this.substrateFilters = this.patternData.SubstrateFilters;
      const tmpPatterns = this.patternData.DesignRows;
      console.log("populatePatternList tmpPatterns", tmpPatterns);
      this.patternList = tmpPatterns;
      this.loaded = true;
    },
    async getPatternListFirstLoad(topCategory) {
      // console.log("getPatternListFirstLoad")
      let returnData = await this.fetchCategoryData(topCategory);
      this.patternData = await JSON.parse(returnData);
      // console.log("getPatternListFirstLoad", this.patternData);

      await this.populateImageList();
      console.log("rocket");
      await this.populatePatternList();
      // this.getPatternList();
    },
    async getPatternList() {
      // console.log("getPatternList");
      // if (this.currentPath === 'PublicLibrary'){
      //   return;
      // }
      let patternData = await this.fetchPatternConfig();
      // let patternData = await this.getCategoryData();
      this.patternData = JSON.parse(patternData);
      // console.log("getPatternList", this.patternData);
      // this.patternData.ttPatternTags
      // log("getRestOFPatternList", this.patternData);
      await this.populateImageList();
      console.log("starlord");
      await this.populatePatternList();
    },
    getImage(patternId) {
      let returnValue = "";
      // log("patternId: ", patternId);
      // log("imageList: ", this.imageList[0].PatternId);
      let what = _.filter(this.imageList, ['PatternId', patternId]);
      if (what.length > 0) {
        // console.log("What: ", what);
        returnValue = what[0].base64String;
      }
      return returnValue;
    },
    getTags(designName) {
      // console.log("designName: ", designName);

      let returnValue = 'tags ';

      if (designName) {
        designName.forEach(element => {
          let tmp = this.regexSpaces(element).toString();
          returnValue += tmp + ' ';
        });
      }
      // returnValue = designName.toString();
      // const _regexSpaces = this.regexSpaces;
      // let tags = "";
      // let what = _.filter(this.patternData.ttPatternTags, ['designName', designName]);
      // if (what) {
      //   _.forEach(what, function (item, key) {
      //     let tag = item.tagValue;
      //     // log("mytag",tag);
      //     tags = tags + " " + _regexSpaces(tag);
      //   });
      //   returnValue = tags;
      // }
      // console.log("Return tags: ", returnValue);
      return returnValue;
    },
    openOrder(id) {
      // log("openOrder", id);
      // log("activePattern", this.activePattern);
      let itemNo = this.activePattern.ItemNo;
      const imageProps = this.imageList[id];
      const patternProps = this.patternList[id];
      for (const ip in imageProps) {
        this.activePattern[ip] = imageProps[ip];
      }
      for (const pp in patternProps) {
        this.activePattern[pp] = patternProps[pp];
      }
      this.activePattern.ItemNo = "Select";
      if (this.loadedFromWizard) {
        this.activePattern.ItemNo = itemNo;
      }
      // Set some defaults to pass to cart object
      this.activePattern.Quantity = 1;
      // this.activePattern.Filename = this.activePattern.PatternName;
      this.activePattern.Filename = this.activePattern.designDescription;
      // console.log("activePattern after openOrder", this.activePattern);
      this.prestageFromWizard();
      if (this.currentPath == 'PublicLibrary') {
        UIkit.modal("#PublicLibraryDialog").show();
      } else {
        UIkit.modal("#LibraryDialog").show();
      }
    },
    closeOrder() {
      this.addToCartSuccess = false;
      // UIkit.modal("#LibraryDialog").hide();
      this.activePattern = {};
      this.libraryMessage = "";
      this.isLibraryError = false;
    },
    /*patternChoiceAvailable(tagValue) {
      let returnValue = true;
      let what = _.filter(this.configData.dtOptionChoices, ['OptionKey', 'ItemNo']);
      // log("patternChoiceAvailable what", what);
      let choice = _.filter(what, ['ChoiceValue', tagValue]);
      // log("patternChoiceAvailable choice", choice);
      returnValue = choice[0].ChoiceAvailable;
      // log("returning", returnValue);
      return returnValue;
    },*/
    sendToCart() {
      // log("sendToCart", this.activePattern);
      if (this.validOrder()) {
        this.libraryMessage = "";
        this.isLibraryError = false;
        let isValid = true;
        if (!_.isNil(this.activePattern)) {
          this.activePattern.product_type = this.product_type;
          // this.activePattern.MaterialText = this.getMaterialText(this.activePattern.ItemNo);
          this.libraryMessage = "Added " + this.activePattern.Quantity + " " + this.activePattern.PatternName + " printed on " + this.activePattern.MaterialText;
          if (this.activePattern.Quantity < 1 || _.isNil(this.activePattern.Quantity)) {
            this.libraryMessage = "Must have a quantity for " + this.activePattern.designDescription;
            this.isLibraryError = true;
            isValid = false;
          }
          if (!this.activePattern.ItemNo) {
            this.libraryMessage = "Must choose a substrate for " + this.activePattern.designDescription;
            this.isLibraryError = true;
            isValid = false;
          }
          if (!this.activePattern.rollSize) {
            this.libraryMessage = "Must choose Roll Size for " + this.activePattern.designDescription;
            this.isLibraryError = true;
            isValid = false;
          }
        }
        if (isValid) {
          this.addPatternToCart();
        }
      }
    },
    goToCart() {
      // log("Go To Cart...");
      uikit.modal("#LibraryDialog").hide();
      // log("did i hide dialog");
      router.push({name: 'cart'})
    },
    toggleBackground(key1, key2) {
      // console.log("toggleBackground", key);
      let item = this.patternList[key1][key2];
      item.showBackground = !item.showBackground;
      if (item.isOpaque) {
        item.showBackground = false;
      }
    },
    updatePricing: _.debounce(function () {
      // console.log("updatePricing");
      const url = this.baseUrl + "/Store/GetPricing";
      const {user} = useAuthStore();
      let _this = this;
      // clone so we can scrub b64 from the request
      let patternData = _.clone(this.activePattern);
      patternData.userId = user.Id;
      patternData.base64String = "";
      patternData.product_type = this.product_type;
      let data = [];
      data.push(patternData);
      // log("pricing data", data);
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      }).then(returnedData => {
        // log("update pricing response", returnedData);
        return returnedData.json();
      }).then((jsondata) => {
        let response = jsondata;
        // log("pricing response json", response);
        if (response.Status === "ok") {
          let pData = response.Data;
          // log("pData", pData);
          if (!_.isEmpty(pData)) {
            _this.activePattern.Price = pData[0].Total;
          }
        }
      });
    }, 500),
    genFilterString(list) {
      let filterString = "";
      if (list) {
        list.forEach(element => {
          if (element !== 'designer') {
            filterString += '.' + element + ', ';
          } else {
            if (this.franchise === "NGS") {
              filterString += '.' + element + ', ';
            }
          }
        });
        // remove comma and space after last element
        return filterString.slice(0, -2);
      }
    },
    regexSpaces(fixString) {
      let newString = fixString.replace(/\s/g, '-');
      return newString;
    },
    editInDesigner() {
      localStorage.setItem("artxId", 669);
      router.push({name: 'designer'});
    },
    getCategoryTag(item) {
      let tags = this.getTags(item.designName);
      let values = this.patternData.ttTagValues;
      // filter values to only include ttTagValues who tagType was "application_type"
      let options = _.filter(values, ['tagType', 'application_type']);

      // filter options again to only include values whos tagName is contained in tags and only return the string value
      let category = _.map(_.filter(options, function (o) {
        return tags.includes(o.tagName);
      }), 'tagValue').toString();

      // console.log("Categories that match: ", category);

      // let categories = 
      // if (this.getTags(item))
      return category;
    },
    getSubstrateTag(item) {
      let tags = this.getTags(item.designName);
      let values = this.patternData.ttTagValues;

      let options = _.filter(values, ['tagType', 'substrate']);
      // console.log("options from getSubstrateTag", options);

    },
    compareTags(item, prevItem) {
      let tags = item.scrollTag;
      let prevTags = prevItem.scrollTag;
      // console.log("tags", tags);
      // console.log("prevTags", prevTags);
      if (tags === prevTags) {
        // console.log("Should do nothing")
        return false;
      } else {
        // console.log("Should create a div")
        return true;
      }
    },
    scrollTo(element) {
      // console.log("scrollIntoView", element);
      let id = element;
      let el = document.getElementById(id);
      // console.log("el", el);
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    toggleFilter(tag) {
      // console.log("toggleFilter", tag);
      if (this.patternFilters.includes(tag)) {
        this.patternFilters = this.patternFilters.filter(item => item !== tag);
        // console.log(tag, " removed from patternFilters: ", this.patternFilters);
      } else {
        this.patternFilters.push(tag);
        // console.log(tag, " added to patternFilters: ", this.patternFilters);
      }
    },
    clearPatternFilters() {
      // console.log("clearPatternFilters");
      this.patternFilters = [];
    },
    getRowTag(row, index) {
      if (row.length > 0) {
        return row[0].scrollTag;
      } else {
        return index;
      }
    },
    handleScroll() {
      const elements = document.querySelectorAll(".patternGroup");
      // console.log("elements", elements);
      elements.forEach((el) => {
        let tag = el.id.substring(9, el.id.length);
        // console.log("tag", tag);
        let sBottom = window.innerHeight;
        const elTop = el.getBoundingClientRect().top;
        const elBottom = el.getBoundingClientRect().bottom;
        let filter = 'filter' + tag;
        let filterEl = document.getElementById(filter);
        if ((elTop - sBottom >= 0) || (elBottom <= 0)) {
          filterEl.classList.remove("spy-active");
        }
        if ((elTop - sBottom <= 0) && (elBottom >= 0)) {
          if (!filterEl.classList.contains("spy-active")) {
            // console.log("Adding spy-active to ", filter);
            filterEl.classList.add("spy-active");
            // this.getNextTag();
          }
        }
      })

    },
    // This call was for fetching data on scroll. Not currently needed
    // async getNextTag () {
    //   console.log("getNextTag");
    //   let nextTag = '';
    //   for (let i = 0; i < this.scrollTags.length; i++){
    //     if (!this.loadedScrollTags.includes(this.scrollTags[i])){
    //       nextTag = this.scrollTags[i];
    //       console.log("Found next tag: ", nextTag);
    //       break;
    //     }
    //   }
    //   // this.scrollTags.forEach((scroll, index) => {

    //   //   if (!this.loadedScrollTags.includes(scroll)){
    //   //     nextTag = scroll;
    //   //     console.log("Found next tag: ", nextTag);
    //   //     return;
    //   //   }
    //   // });
    //   let newData = await this.fetchCategoryData(nextTag);
    //   newData = JSON.parse(newData);
    //   console.log("newData", newData);
    //   this.patternData.DesignRows.push(newData.DesignRows[0]);
    //   newData.Designs.forEach(element => {
    //     this.imageList.push(element);
    //     this.patternData.Designs.push(element);
    //   });
    //   this.populateImageList();
    //   this.populatePatternList();
    //   // this.patternData.Designs.push(newData.Designs);
    //   // if (newData.DesingRows.length > 0){
    //   //   this.patternList.push(newData.DesingRows[0]);
    //   // }
    // }
  },

  watch: {
    "activePattern.ItemNo": function (val) {
      // log("ItemNo/Material Change: ", val);
      // this.setConfigData();

    },
    "activePattern.LaminateType": function (val) {
      // log("LaminateType Change: ", val);
      // this.setConfigData();
    },
    "activePattern.Type": function (val) {
      // log("Type Change: ", val);
      // this.setConfigData();
    },
    "activePattern.Quantity": function (val) {
      // log("Quantity Change: ", val);
      if (this.currentPath !== 'PublicLibrary') {
        this.setConfigData();
      }
    },
    currentPath: function (val) {
      // log("router.route Change: ", val);
      if (val == 'patternLibrary') {
        this.setConfigData();
        this.openOrder(this.activePattern.PatternNumber);
        // console.log("Chaning page to : ", this.currentPage);
      }
    },
  }
};
</script>
<style scoped>
.spy-active {
  background-color: black;
  color: white !important;
  padding: 5px 10px;
  padding-top: 10px;
  margin-block: 10px !important;
  /* outline: 2px solid black;
  outline-offset: 3px; */
}
</style>
<template>
  <section id="PatternLibrarySection" class="uk-section uk-width-1-1 uk-padding-remove-top uk-position-relative">
    <LoadingAnimation v-if="!this.loaded"></LoadingAnimation>
    <!-- start of filter elements -->
    <button v-if="this.loaded" class="uk-button uk-button-default uk-margin-bottom"
            uk-toggle="target: #FilterControls">Filter
    </button>
    <!-- <button v-if="this.loaded" class="uk-button uk-button-primary project-button uk-margin-bottom uk-margin-left" @click="editInDesigner">Test Designer Pre-load</button> -->
    <div v-if="this.loaded" class=" uk-panel content-container uk-flex uk-width-1-1"
         uk-filter="target: .js-filter; selector: .uk-filter-control; animation: delayed-fade">

      <div v-if="this.loadingError" class="uk-alert uk-alert-warning">{{ this.loadingError }}</div>
      <div class="uk-container uk-flex uk-width-1-1">
        <div id="FilterControls"
             class="filter-controls uk-list uk-box-shaddow-medium uk-margin-right uk-width-medium uk-position-relative"
        >
          <div class="uk-width-medium" uk-sticky="offset: 200">
            <h3 class="uk-margin-top">Quick Scroll</h3>
            <div class="quick-scroll-container uk-margin-bottom">
              <div v-for="tag in scrollTags" :class="'filter' + tag" class="uk-margin-small-bottom">
                <!-- <a  @click="scrollTo(tag)" class="uk-text-primary uk-margin-left">{{ tag }}</a> -->
                <!-- Set href to tag unless the tag is the top group, then set to top (Wood is top group. If it changes, change wood to top group)-->
                <a :href="tag != 'Wood' ? '#' + tag : '#Top' " :id="'filter' + tag"
                   class="uk-text-primary uk-margin-left"
                   uk-scroll="offset: 15">{{ tag }}</a>
              </div>
            </div>
<!--            <div v-if="this.loaded" uk-filter-control>-->
            <div v-if="showFilterControls"  uk-filter-control>
              <h3>Filters</h3>
              <ul class="filter-container uk-width-medium uk-list uk-margin-left">
                <li @click="clearPatternFilters">
                  <a class="uk-button uk-button-default uk-margin-bottom" href="#">Clear Filters</a>
                </li>
                <!-- <li class="uk-active" v-for="data in patternData.ttTagValues"
                    :uk-filter-control="'filter: .' + regexSpaces(data.tagValue)"> -->
                <div v-for="tag in substrateFilters" class="uk-margin-remove">
                  <!-- <li class=""  :uk-filter-control="'filter: .' + regexSpaces(tag) + '; group: substrate'"> -->
                  <li class="" @click="toggleFilter(tag)">
                    <!-- this value needs to be run through a function to replace spaces with hyphens -->
                    <input class="uk-checkbox uk-margin-small-right" type="checkbox"
                           :checked="patternFilters.includes(tag)">
                    <a class="uk-text-primary">{{ tag }}</a>
                  </li>
                </div>
              </ul>
              <!--this is an example of how to continue with more filter options      -->
              <!-- <ul  class="filter-container uk-width-medium uk-list uk-flex uk-flex-column uk-padding uk-padding-remove-horizontal">
                <li> <a href="#">Categories</a> </li>
                <li v-for="category in patternData.ttTagCategories" :uk-filter-control="'filter: .' + regexSpaces(category.tagType)">
                  <a class="uk-text-primary uk-margin-left" href="#">{{ category.tagType }}</a></li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="uk-width-expand">
          <ul class="js-filter ">
            <ul v-for="(designRow, index1 ) in patterns" :id="'container' + getRowTag(designRow, index1)"
                :class="{'patternGroup uk-width-1-1 uk-margin-large-bottom' : designRow.length > 0, 'uk-margin-remove' : designRow.length == 0}">

              <div v-if="designRow.length > 0" class="">
                <div :id="designRow[0].scrollTag" class="scrollspy uk-text-lead">{{ designRow[0].scrollTag }}</div>
                <hr class="uk-margin-remove-top uk-margin-medium-bottom">
              </div>
              <div v-if="designRow.length > 0"
                   class="uk-width-1-1 uk-child-width-1-1@s uk-child-width-1-3@m uk-grid-row-large"
                   uk-grid>
                <li v-for="(designItem, index2) in designRow"
                    :key="index2"
                    :class="getTags(designItem.tagList)">
                  <!-- <div v-if="index2 !=0 && compareTags(designItem, designRow[index2 - 1])">
                    <div :id="designItem.scrollTag" class="uk-margin-bottom uk-text-secondary"></div>
                  </div> -->
                  <div v-if="designItem.PatternName" :class="{'patn-library-card-hover': !designItem.isOpaque}"
                       class="patn-library-card uk-card uk-card-default uk-card-small">
                    <div class="uk-media-top uk-inline uk-margin-small-bottom">
                      <div class="uk-cover-container"
                           v-bind:class="{ 'patn-background-image': designItem.showBackground, 'patn-background-color': !designItem.showBackground }">
                        <canvas width="480" height="480" style="pointer-events: none"></canvas>
                        <img v-if="designItem.designThumb" v-bind:src="getImage(designItem.designThumb)" class="patn-img" uk-cover>
                      </div>
                      <div class="media-controls uk-position-bottom-right">
                        <button v-if="designItem.showBackground && !designItem.isOpaque"
                                v-on:click="toggleBackground(index1, index2)"
                                class="uk-toggle-icon uk-icon-button" uk-icon="world"></button>
                        <button v-if="!designItem.showBackground && !designItem.isOpaque"
                                v-on:click="toggleBackground(index1, index2)"
                                class="uk-toggle-icon uk-icon-button" uk-icon="ban"></button>
                      </div>
                    </div>
                    <div class="uk-card-body uk-padding-remove uk-flex uk-flex-column uk-flex-center uk-text-center">
                      <div style="font-weight: bolder" class="uk-text-left uk-text-break uk-margin-small-bottom">
                        {{ designItem.designDescription }}
                      </div>
                      <div class="card-bottom">
                        <div>
                          <button class="uk-button uk-button-default uk-width-1-1"
                                  v-on:click="openOrder(designItem.PatternNumber)"><span
                              uk-icon="icon: cart"> </span>
                            Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </ul>
        </div>
        <!-- grid-->
      </div>
      <!-- container-->
    </div>

    <!-- add container with the id of the parent to prevent multiple modals appearing in the DOM -->
    <div id="LibraryDialog" class="uk-modal" uk-modal="bg-close: false" container="#PatternLibrarySection">
      <div class="uk-modal-dialog uk-width-1-1 uk-height-1-1">
        <div class="uk-section uk-position-cover uk-child-align-middle uk-padding-remove">
          <div class="uk-position-cover uk-padding uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding">
            <div v-if="libraryMessage" class="patn-library-alert uk-alert uk-padding-small"
                 :class="{ 'patn-library-alert-success': !isLibraryError, 'patn-library-alert-danger': isLibraryError }"
                 uk-alert>
              <p class="uk-text-lead">{{ libraryMessage }}</p>
            </div>
            <div
                class="patn-order-section uk-flex uk-child-height-1-1 uk-height-4-5 uk-flex-around uk-flex-wrap uk-margin-remove">
              <div class="image-container uk-position-relative uk-padding-remove uk-margin-right uk-align-right">
                <div :class="{'patn-library-card-hover': !activePattern.isOpaque}"
                     class="patn-library-card uk-flex uk-flex-middle uk-flex-center">
                  <div class="uk-cover-container"
                       v-bind:class="{'patn-background-image': activePattern.showBackground, 'patn-background-color': !activePattern.showBackground }">
                    <canvas width="480" height="480" style="pointer-events: none"></canvas>
                    <img class="patn-img" v-bind:src="activePattern.base64String" uk-cover>
                  </div>
                </div>
              </div>
              <div
                  class="patn-order-section input-container uk-flex-stretch uk-flex-wrap-middle uk-padding uk-padding-remove-vertical uk-flex-1">
                <h1>{{ activePattern.designDescription }}</h1>
                <div class="uk-margin-bottom">
                  <template v-for="option in productOptionChoices" :key="option.OptionKey">
                    <!--  Select Options -->
                    <div v-bind:id="'col_' + option.OptionKey"
                         class="uk-margin-bottom" v-bind:hidden="!checkOptionAvailable(option.OptionKey)">
                      <label class="uk-text-warning">
                        {{ option.OptionName }}
                        <select v-model="activePattern[option.OptionKey]" v-on:change="choiceChange"
                                v-bind:name="option.OptionKey" class="uk-select uk-margin-left">
                          <option value="Select" disabled> Select</option>
                          <option v-for="choice in initChoiceOptions(option.OptionKey)" :value="choice.ChoiceValue"
                                  v-bind:disabled="!choice.ChoiceAvailable"
                                  v-bind:class="{ choiceDisabled: !choice.ChoiceAvailable }"
                                  v-text:="getChoiceText(choice.ChoiceName, choice.ChoiceAvailable)">
                          </option>
                        </select>
                      </label>
                    </div>
                  </template>
                </div>
                <div class="uk-margin-bottom" uk-grid>
                  <div class="uk-width-1-2">
                    <span class="uk-text-warning"><strong>Qty: </strong></span>
                    <input maxlength="3" class="uk-input uk-margin-left" type="number" placeholder="Qty"
                           v-model="activePattern.Quantity" name="qty-input">
                  </div>
                  <div v-if="showPrice" class="uk-width-1-2">
                    <span><strong>Price: </strong></span>&nbsp;<span
                      class="uk-input uk-text-center">{{ $toCurrency(this.activePattern.Price) }}</span>
                  </div>
                  <div class="button-container uk-flex uk-child-width-1-2 uk-width-1-1">
                    <button v-if="addToCartSuccess" class="uk-button uk-button-success uk-flex-auto uk-margin-right"
                            v-on:click="goToCart()">Go to Cart
                    </button>
                    <button v-else v-if="validOrder"
                            class="uk-button uk-button-primary project-button uk-flex-auto uk-margin-right"
                            v-on:click="sendToCart()">Add to Cart
                    </button>
                    <button class="uk-button uk-button-default uk-flex-auto uk-modal-close"
                            v-on:click="closeOrder()"><span
                        uk-icon="icon: refresh"> </span>Return to Library
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- container-->
        </div>
        <!-- section-->
      </div>
      <!-- modal-->
    </div>
    <!-- dialog-->

    <!-- Public Pattern Library-->
    <div id="PublicLibraryDialog" class="uk-modal" uk-modal="bg-close: false" container="#PatternLibrarySection">
      <div class="uk-modal-dialog uk-width-1-1 uk-height-1-1">
        <div class="uk-section uk-position-cover uk-child-align-middle uk-padding-remove">
          <div class="uk-position-cover uk-padding uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding">
            <div v-if="libraryMessage" class="patn-library-alert uk-alert uk-padding-small"
                 :class="{ 'patn-library-alert-success': !isLibraryError, 'patn-library-alert-danger': isLibraryError }"
                 uk-alert>
              <p class="uk-text-lead">{{ libraryMessage }}</p>
            </div>
            <div class="patn-order-section uk-padding-remove uk-margin-remove">
              <div class="image-container uk-position-relative uk-padding-remove uk-margin-remove">
                <div :class="{'patn-library-card-hover': !activePattern.isOpaque}"
                     class="patn-library-card uk-flex uk-flex-middle uk-flex-center">
                  <div class="uk-cover-container"
                       v-bind:class="{'patn-background-image': activePattern.showBackground, 'patn-background-color': !activePattern.showBackground }">
                    <canvas width="480" height="480" style="pointer-events: none"></canvas>
                    <img class="patn-img" v-bind:src="activePattern.base64String" uk-cover>
                  </div>
                </div>
              </div>
              <div class="patn-order-section">
                <h3 class="uk-padding-top uk-padding-small uk-padding-remove-horizontal uk-margin-remove uk-text-center">
                  {{ activePattern.designDescription }}</h3>
                <!-- <div class="button-container uk-flex uk-child-width-1-2 uk-width-1-1"> -->
                <div class="uk-flex uk-flex-between uk-width-1-1 uk-child-width-1-2">
                  <button class="uk-button uk-button-default uk-margin-right"
                          v-on:click="setCurrentPattern" uk-toggle="target: #LoginModal"><span
                      uk-icon="icon: refresh"> </span>Order
                  </button>
                  <button class="uk-button uk-button-default uk-modal-close">
                    <span uk-icon="icon: refresh"></span>View Library
                  </button>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <!-- container-->
        </div>
        <!-- section-->
      </div>
      <!-- modal-->
    </div>
    <!-- dialog-->
  </section>
</template>